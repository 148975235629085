<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 488.1 451.8"
        width="10"
        height="10"
    ><path
        fill="#afb2b9"
        d="M478.9 248.3L284.6 442.6c-12.4 12.4-32.4 12.4-44.7 0-12.4-12.4-12.4-32.4 0-44.7L411.8 226l-172-172c-12.4-12.4-12.4-32.4 0-44.7 12.4-12.4 32.4-12.4 44.8 0l194.3 194.3c6.2 6.2 9.3 14.3 9.3 22.4-.1 8-3.2 16.1-9.3 22.3z"
    /><path
        fill="#afb2b9"
        d="M248.3 248.3L54 442.6c-12.4 12.4-32.4 12.4-44.8 0-12.4-12.4-12.4-32.4 0-44.7L181.1 226 9.3 54C-3.1 41.7-3.1 21.6 9.3 9.3 21.6-3.1 41.7-3.1 54 9.3l194.3 194.3c6.2 6.2 9.3 14.3 9.3 22.4 0 8-3.1 16.1-9.3 22.3z"
    /></svg>
</template>

<script>
export default {
    name: 'ArrowDouble'
}
</script>
<style scoped lang='scss'>
svg path {
    fill: #afb2b9;
}
</style>
