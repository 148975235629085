<template>
    <div
        v-if="dialog"
        class="agreement-modal"
        @click.self="close()"
    >
        <div class="agreement-modal__dialog">
            <header class="agreement-modal__header">
                <h2>Terms of service</h2>
                <span
                    class="agreement-modal__close"
                    @click="close()"
                ><CloseIcon /></span>
            </header>
            <section
                id="scrollArea"
                class="agreement-modal__content"
                @scroll="scrollDetect"
            >
                <p class="agreement-content__text">
                    By completing and submitting the order form you agree to be bound by and are becoming a party to the current agreement.
                </p>

                <h1 class="agreement-content__title">
                    DELIVERY
                </h1>
                <p class="agreement-content__text">
                    The completed order form is the set of directions that will be followed by our writers to provide a digital research/writing service that will be delivered by electronic mail at your e-mail address specified above as well as via direct download off our site. The login information to your secure personal account shall be provided upon validating your billing details. We offer the free direct download feature that allows to download all project files directly from your personalized account (Customer Service Area)
                </p>

                <h1 class="agreement-content__title">
                    TERMS OF USE
                </h1>
                <p class="agreement-content__text">
                    You agree that the written material produced by {{ domain }} represents a model work that should not be submitted for academic credit "as is". You may use the work for further research or may edit it to match your writing style, level and vocabulary. We do not guarantee any specific letter grades or any other form of academic approval and can only guarantee to provide quality work based on the original order description.
                </p>

                <h1 class="agreement-content__title">
                    DEADLINE
                </h1>
                <p class="agreement-content__text">
                    Should we have difficulty meeting your specified deadline, we will contact you prior to the deadline to request an extension, or discuss a discount or other form of compensation suitable to both parties to reach an agreement (partial refund, discount, etc.). While minor delays in delivery do not constitute grounds for a full refund, we will do our best to reach an agreement with the buyer by means of discounts or other forms of partial compensation. Minor delays in delivery do not constitute grounds for a full refund
                </p>

                <h1 class="agreement-content__title">
                    CONTENT
                </h1>
                <p class="agreement-content__text">
                    We shall complete your project based solely on the instructions supplied in the order form. The writer will be responsible for meeting all the instructions. We shall not be responsible for processing additional instructions not included in the original project description. Such requests may still be accomplished as a one time courtesy at the sole discretion of the writer. Minor content problems do not constitute grounds for a full refund
                </p>

                <h1 class="agreement-content__title">
                    PLAGIARISM
                </h1>
                <p class="agreement-content__text">
                    The content is written from scratch by a competent writer and contains no plagiarism. All quotes and other people's ideas shall be properly documented using the specified citation style. It is our guarantee that direct quotes shall not take more than 20% of the content, unless specifically requested by the buyer. Should the buyer find two or more straight sentences (not quotations, at least 20 words total in a straight sequence) copied from another source verbatim WITHOUT PROPER CITATION, we will offer you either a complete rewrite or a refund.
                </p>

                <h1 class="agreement-content__title">
                    REVISIONS/REWRITES
                </h1>
                <p class="agreement-content__text">
                    The company guarantees to adequately meet the original requirements set forth by the buyer. Should our writer overlook some guidelines, the buyer will need to file a revision request through the company's site (Support Center) and the writing shall be corrected free of charge, unless the request is beyond the original project description. The revision shall be delivered promptly. We reserve the right to reject the revision requests submitted after 14 days from the moment of completion. If the revision request is beyond the original project description, the buyer will have to submit a new order (EDITING) or contact the company regarding some extra compensation.
                </p>

                <h1 class="agreement-content__title">
                    CUSTOMER SATISFACTION GUARANTEE
                </h1>
                <p class="agreement-content__text">
                    If you are dissatisfied with the service provided by our company, we will have the work revised, corrected or rewritten until all the initial requirements are adequately followed. If there are some minor issues, you can request a revision and have the work corrected promptly and free of charge. The buyer can also request a different writer, if there is a valid concern. The company will revise or rewrite the project free of charge until the work fully meets the original order description. Mere dissatisfaction with the service does not constitute grounds for a full refund.
                </p>

                <h1 class="agreement-content__title">
                    REFUND POLICY
                </h1>
                <p class="agreement-content__text">
                    The company guarantees to adequately meet the original requirements set forth by the buyer. Should our writer overlook some guidelines, the buyer will need to file a revision request through the company's site (Support Center) and the writing shall be corrected free of charge unless the request is beyond the original project description. The revision shall be delivered promptly. We reserve the right to reject the revision requests submitted after 14 days from the moment of completion. If the revision request is beyond the original project description, the buyer will have to submit a new order (EDITING) or contact the company regarding some extra compensation 14 days after completion for 1-10 pages and 30 days after completion for big orders.
                </p>

                <h1 class="agreement-content__title">
                    CONFIDENTIALITY
                </h1>
                <p class="agreement-content__text">
                    We can guarantee that no information related to your using our service will ever be distributed to any third party without your consent. The company guarantees not to reuse or resell the works produced for the clients, and the company's staff writers are bound by the same agreement.
                </p>

                <h1 class="agreement-content__title">
                    CONTACT AND DISPUTE RESOLUTION
                </h1>
                <p class="agreement-content__text">
                    Should you be dissatisfied with the service provided by the company, you agree to always contact the company first for direct resolution. The company should be contacted through Live Chat or by submitting a support ticket through our Support Center. If you have not been able to reach an acceptable solution after 14 days since the time of your first contact via the aforementioned communication channels, you may contact other organizations for assistance. However, failure to contact our company directly before initiating a chargeback shall be considered a breach of contract and shall be disputed accordingly.
                </p>

                <p class="agreement-content__text">
                    {{ domain }} is owned and operated by MEDIATECH LIMITED. Company registration number 1242512.
                    Any relevant information should be faxed at 1-866-308-7123, 1-404-963-0617
                    mailed to Office 813, 8/F, 610 Nathan Road, Hollywood Plaza, HK.
                    or emailed at hk@freelancewritingcenter.com
                </p>
            </section>
            <footer>
                <div class="confirm-modal__one-button-block confirm-modal__accept">
                    <!-- <button
                        class="confirm-modal__two-button confirm-modal__two-button--gray"
                        @click="close()"
                    >
                        cancel
                    </button> -->
                    <button
                        v-if="scrollFinished===true"
                        class="confirm-modal__one-button confirm-modal__one-button--main"
                        @click="submit()"
                    >
                        i accept
                    </button>
                    <span
                        v-else
                        class="confirm-modal__scroll"
                        @click="scrollDown"
                    >
                        Scroll down to read
                        <arrow-double />
                    </span>
                </div>
            </footer>
        </div>
    </div>
</template>

<script>
import CloseIcon from 'mdi-vue/Close.vue';
import ArrowDouble from '@/components/icons/ArrowDouble.vue';
import Helper from '@/helpers/functions'

export default {
    name: 'AgreementModal',
    components: {
        CloseIcon,
        ArrowDouble
    },
    data() {
        return {
            dialog: false,
            scrollFinished: false
        }
    },
    computed: {
        domain() {
            return process.env.VUE_APP_DOMAIN
        }
    },
    watch: {
        dialog(val) {
            Helper.lockBody(val)
        }
    },
    methods: {
        open() {
            this.dialog = true
            return new Promise((resolve, reject) => {
                this.resolve = resolve
                this.reject = reject
            })
        },
        close() {
            this.resolve(false);
            this.dialog = false;
            this.scrollFinished = false
        },
        submit() {
            this.$emit('terms-agree')
            this.resolve(true)
            this.dialog = false;
            this.scrollFinished = false
        },
        scrollDetect() {
            const o = document.getElementById('scrollArea')
            if (o.offsetHeight + o.scrollTop >= o.scrollHeight - 10) {
                this.scrollFinished = true
            }
        },
        scrollDown() {
            const elem = document.getElementById('scrollArea')
            elem.scrollTo({
                top: 5000,
                behavior: 'smooth'
            });
        }
    }
}
</script>

<style lang="scss" >
.agreement-modal {
    position: fixed;
    overflow: hidden;
    background-color: rgba(11, 19, 31, .45);
    backdrop-filter: blur(3px);
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100vh;
    overscroll-behavior: contain;
    z-index: 900;
    display: flex;
    justify-content: center;
    align-items: center;

    .confirm-modal {
        &__accept {
            padding: 20px;
        }
        &__scroll {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            color: $font-color-main;
            cursor: pointer;
            svg {
                margin: 5px;
                transform: rotate(90deg);
                path{
                    fill:$font-color-main;
                }
            }
        }
    }

    &__dialog {
        max-width: 500px;
        width: 100%;
        background-color: #fff;
        -webkit-box-shadow: 0 10px 40px rgba(25, 50, 71, .2);
        box-shadow: 0 10px 40px rgba(25, 50, 71, .2);
        border-radius: $input-border-radius;
        //position: fixed;
        top: 90px;
        left: calc(50% - 250px);
        z-index: 999999999;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    &__header {
        font-weight: 500;
        color: $font-color-main;
        font-size: 24px;
        border-bottom: 1px solid $border-light;
        padding: 20px;
        position: relative;
    }
    &__close {
        position: absolute;
        right: 20px;
        top: 50%;
        transform: translateY(-50%);
        color: $second-color;
        cursor: pointer;
    }
    &__content {
        padding: 20px 20px 0;
        color: #757c89;
        font-size: 16px;
        line-height: 1.3;
        overflow-y: scroll;
        max-height: 500px;
    }
    &__two-button-block {
        display: flex;
        justify-content: space-between;
        padding: 20px;
    }
    &__one-button-block {
        display: flex;
        justify-content: center;
        padding: 20px;
    }
    &__two-button {
        padding: 20px 0;
        width: calc(50% - 10px);
        text-transform: uppercase;
        font-size: 16px;
        font-weight: bold;
        border: none;
        border-radius: $btn-base__border-radius;
        cursor: pointer;
        &--gray {
            background-color: #f5f5f5;
            &:hover {
                background-color: rgba(145, 149, 154, 0.2);
                transition: 0.5s;
            }
        }
        &--main {
            background: $btn-main-background;
            box-shadow: $confirm-modal__button-shadow;
            color: $btn-main-color;
            &:hover {
                background: $btn-main-background-hover;
                color: $btn-main-color-hover;
                transition: 0.5s;
            }
        }
    }
    &__one-button {
        padding: 20px 0;
        width: 80%;
        text-transform: uppercase;
        font-size: 16px;
        font-weight: bold;
        border: none;
        border-radius: $btn-base__border-radius;
        cursor: pointer;
        &--main {
            background: $btn-main-background;
            box-shadow: $confirm-modal__button-shadow;
            color: $btn-main-color;
            &:hover {
                background: $btn-main-background-hover;
                transition: 0.5s;
                color: $btn-main-color-hover;
            }
        }

    }
}

.agreement-content {
    &__text {
        margin: 10px 0;
        line-height: 30px;
    }
    &__title {
        margin: 15px 0 10px;
    }
}

@media (max-width: 576px) {
    .agreement-modal {
        &__content {
            max-height: 320px;
            padding: 20px 20px 0;
        }

        &__header {
            margin-bottom: 0;
        }
        &__dialog {
            left: 0;
        }

        .confirm-modal__two-button-block {
            padding: 20px;
        }
    }
}

</style>
